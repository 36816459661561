import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import SampleForm from '../components/Form'
import RightSide from '../components/rightside-general'

import BG from '../images/awards-bg.jpg'

class Contact extends React.Component {
  render() {
    const siteTitle = 'Contact'
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div
          id="sliderhead"
          style={{
            background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
            position: 'relative',
          }}
          className="blog-header2"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-8 left-list">
              <div id="contentgen">
                <h3>Contact Details</h3>
                <br />
                <h5 class="cont-ttle2">Contact Form</h5>
                <h4 class="cont-sub">Please fill in the form below.</h4>
                <SampleForm
                  form_name="Newsletter Form"
                  form_id="5cbfd5960c0b2f1f672fbd9f"
                  form_classname="form-newsletter"
                >
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Name"
                          id="name"
                        />
                        <label htmlFor="name">NAME</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Company"
                          id="company"
                        />
                        <label htmlFor="company">COMPANY</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Address"
                          id="address"
                        />
                        <label htmlFor="address">ADDRESS</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="number"
                          name="Phone"
                          id="phone"
                        />
                        <label htmlFor="phone">PHONE</label>
                      </div>
                    </div>
                    <div className="col-6 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="number"
                          name="Fax"
                          id="fax"
                        />
                        <label htmlFor="fax">FAX</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="email"
                          name="Email Address"
                          id="emailaddress"
                        />
                        <label htmlFor="emailaddress">EMAIL ADDRESS</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          name="Message"
                          id="message"
                        />
                        <label htmlFor="message">YOUR MESSAGE</label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="webriq-recaptcha" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <button
                          className="btn btn-primary hvr-shadow"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </SampleForm>
              </div>
            </div>
            <div className="col-md-4 right-list pull-right">
              <RightSide />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact

export const ContactPageQuery = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
